<template>
    <div>
        <div class="row">
            <div class="col-md-2">
                <div class="row mb-3">
                    <h3 class="col">Users</h3>
                </div>
                <name-card v-for="index in 10" :key="index"></name-card>
            </div>
            <div class="col-md-10">
                <customer-details></customer-details>
            </div>
        </div>
    </div>
</template>

<script>
import NameCard from '@/views/customers/customerComponents/nameCard';
import CustomerDetails from '@/views/customers/customerComponents/customerDetails';
export default {
    name       : 'customers',
    components : { CustomerDetails, NameCard }
};
</script>

<style scoped>

</style>
