<template>
<div>
    <div class="card shadow">
        <p>address card</p>
    </div>
</div>
</template>

<script>
export default {
    name : 'addressCard'
};
</script>

<style scoped>
.shadow {
    box-shadow: 0 4px 24px 0 rgb(0 0 0 / 20%);
}
</style>
