<template>
    <div>
        <div class="row">
            <h3 class="col">User name</h3>
        </div>
        <div class="fl-eq">
            <address-card v-for="index in 4" :key="index"></address-card>
        </div>
        <div class="card mt-3 shadow">
            <simple-table :fields="fields" :data="data">
                <template slot="actions" slot-scope="item">
                    <div class="btn-group">
                        <btn color="primary" size="sm" @click="viewItem(item.rowData)" text="Edit">
                        </btn>
                    </div>
                </template>
            </simple-table>
        </div>
    </div>
</template>

<script>
import AddressCard from '@/views/customers/customerComponents/addressCard';

export default {
    name       : 'customerDetails',
    components : { AddressCard },
    props      : {
        data : {
            type : Array
        }
    },
    data () {
        return {
            fields : [
                {
                    name       : '',
                    sortField  : '',
                    title      : 'Order ID',
                    titleClass : ''
                },
                {
                    name       : 'e',
                    sortField  : '',
                    title      : 'Price',
                    titleClass : ''
                },
                {
                    name       : '',
                    sortField  : 'No Of Items',
                    title      : '',
                    titleClass : ''
                },
                {
                    name       : '',
                    sortField  : '',
                    title      : 'Date and time',
                    titleClass : ''
                },
                {
                    name       : '',
                    sortField  : '',
                    title      : 'address',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    sortField  : '',
                    title      : '',
                    titleClass : ''
                }
            ]
        };
    },
    methods : {
        viewItem () {
            alert('view');
        }
    }
};
</script>

<style scoped>
.shadow {
    box-shadow: 0 4px 24px 0 rgb(0 0 0 / 20%);
}
</style>
